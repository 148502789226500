
<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Fondeadores</h1>
    </div>
    <br />
    <DataGridV3 :configuracion="dataGridConfig" :data="dataSourceGrid" :seleccionar="seleccionar" ref="gridFondeadores"  @ready="cargarAcciones">
      <div class="row">
        <div class="col-sm-auto">
          <button class="btn btn-info" @click="cargar()"> <i class='fa fa-refresh'></i> Recargar</button>
        </div>
        <div class="col-sm-auto" v-if="$auth.can('garantias_app','fondeadores_crear')">
          <button  class="btn btn-primary" @click="crear"><i class='fa fa-plus-circle'></i> Nuevo</button>
        </div>
      </div>

    </DataGridV3>

    <Modal v-if="openModalForm" :options="{width: '45vw',type:'info'}" @close="cancelar_seleccion">
      <div class="title">{{ !itemSeleccionado.id ? 'Agregar Fondeador' : 'Editar Fondeador'}}</div>
      <div class="body">

        <div class="alert alert-primary mb-5" role="alert">
          <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b> Los campos que contengan el icono <i class="fa fa-check-circle mx-sm-1"></i> indican que el campo es obligatorio.
        </div>

        <div class="row justify-content-center mt-4">
          <div class="col-12 form-group">
            <label for="indentificador" class="form-label font-weight-bold text-truncate d-block" title="Estatus">
              Identificador externo
            </label>
            <input v-model="itemSeleccionado.identificador_externo" type="text" name="identificador_externo" id="identificador_externo" class="form-control">
          </div>

          <div class="col-12 form-group">
            <label for="nombre" class="form-label font-weight-bold text-truncate d-block" title="Estatus">
              <i class="fa fa-check-circle text-primary"></i>
              Nombre
            </label>
            <input v-model="itemSeleccionado.nombre" type="text" name="nombre" id="nombre" class="form-control">
          </div>

          <div class="col-12 form-group">
            <label for="estatus" class="form-label font-weight-bold text-truncate d-block" title="Estatus">
              <i class="fa fa-check-circle text-primary"></i>
              Estatus
            </label>
            <select class="form-control" name="estatus" id="estatus"  v-model="itemSeleccionado.estatus" >
              <option value="activo">Activo</option>
              <option value="inactivo">Inactivo</option>
            </select>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="guardar"><i class="fa fa-check-square mr-sm-1"></i>{{ !itemSeleccionado.id ? 'Guardar' : 'Guardar Cambios'}}</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>
    


    <Modal v-if="openModalEliminar" :options="{width: '50vw',type:'error'}" @close="openModalEliminar = false">
      <div class="title">Eliminar fondeador </div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Si eliminas el fondeador:  <b>{{ itemSeleccionado.nombre }}</b> se eliminará toda su información y toda relación que tenga con otros registros como con créditos, fideicomisos, beneficiarios, movimientos, etc.  <br/>¿Estas seguro que deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar"><i class="fa fa-check-square mr-sm-1"></i>Si, eliminar fondeador y su relaciones</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>

import Modal from "@/components/Modal.vue";
import DataGridV3 from "@/components/DataGridV3.vue";
import FormularioCliente from "@/apps/garantias_app/pages/clientes/FormularioCliente.vue";
import Cliente from "@/apps/garantias_app/pages/clientes/Cliente.vue";
import fondeadoresApi from "@/apps/garantias_app/api/fondeadores/fondeadores.api";

export default {
  name: "Fondeadores",
  data(){
    return {
      openModalForm: false,
      openModalEliminar: false,
      dataGridConfig: {
        name: 'garantias_fondeadores',
        cols: {
          acciones: '',
          external_id: 'ID externo',
          nombre: 'Nombre',
          estatus: 'Estatus',
        }
        ,filters: {
          cols: {
            external_id: 'ID externo',
            nombre: 'Nombre',
          }
        }
        ,paginator: {
          pagina_actual: 1
          ,total_registros: 1
          ,registros_por_pagina: 20
        }
        ,mutators: {
          acciones: function(val,row,vue) {
            let acciones = "<div class='text-center'>";
            if (vue.$auth.can('garantias_app','fondeadores_editar'))
              acciones += "<i data-action='editar' data-opcion='"+vue.$helper.toJson(row)+"' class='btn-acciones btn-sm btn-primary fa fa-edit mr-sm-2'></i>";

            return acciones+"</div>";
          }
          ,estatus:function (val){
            return val.toUpperCase()
          }
        },
        show_search: true,
        search: ['nombre'],
        selector: false,
      },
      seleccionar: false,
      dataSourceGrid: [],
      accionesCargadas: false,
      itemSeleccionado: {
        id: null,
        identificador_externo: null,
        nombre: null,
        estatus: null
      }
    }

  },
  components: {
    FormularioCliente,
    Cliente,
    DataGridV3,
    Modal
  },
  computed: {

  },
  mounted() {
    this.cargar()
  },
  methods: {
    cargarAcciones() {
      let btns = document.querySelectorAll('.btn-acciones');
      btns.forEach(btn => {
        btn.removeEventListener('click', this.ejecutarAccionBtn )
        btn.addEventListener('click', this.ejecutarAccionBtn)
      })
    },
    ejecutarAccionBtn(e){
      let seleccion  = this.$helper.fromJson(e.target.dataset.opcion);
      if (seleccion.id !== this.itemSeleccionado.id) {
        let accion = e.target.dataset.action;
        this.itemSeleccionado = {...seleccion}
        this.itemSeleccionado.identificador_externo = this.itemSeleccionado.external_id
        this[accion](seleccion);
      }
    }
    ,cancelar_seleccion(){
      this.openModalForm = false;
      this.openModalEliminar = false;

      this.itemSeleccionado = {
        id: null,
        identificador_externo: null,
        nombre: null
      };
    },
    crear(){
      this.cancelar_seleccion()
      this.openModalForm = true;
    },
    async editar(){
      this.openModalForm = true;
    },
    pre_eliminar(){
      this.openModalEliminar = true;
    },

    async guardar(){
      let isValid = await this.$validator({
        identificador_externo: !!this.itemSeleccionado.identificador_externo ? 'max:255' : '',
        nombre: !this.itemSeleccionado.nombre ? 'required' : 'max:150',
        estatus: !this.itemSeleccionado.estatus ? 'required' : '',
      })

      if (isValid) {
        try {
          if (this.itemSeleccionado.id!== null) {
            const request = await fondeadoresApi.editar(this.itemSeleccionado.id,  this.itemSeleccionado)
            this.$helper.showMessage('Editar fondeador', 'Información guardada exitosamente.', 'success', 'alert')

          }else{
            const request = await fondeadoresApi.crear(this.itemSeleccionado)
            this.$helper.showMessage('Crear fondeador', 'Información guardada exitosamente.', 'success', 'alert')
          }
          this.cancelar_seleccion()
          await  this.cargar()

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage( (!this.cliente_id ? 'Guardar' : 'Editar')+ ' fondeador', 'Ocurrio un error al guardar datos del fondeador, favor de reportarlo al administador.', 'error', 'alert')
        }
      }
    },

    async eliminar(){
      this.openModalEliminar = false;
      await fondeadoresApi.eliminar(this.itemSeleccionado.id).then(() => {
      this.$helper.showMessage('Eliminar fondeador','Fondeador eliminado exitosamente.', 'success', 'alert')
      this.cargar()
       this.cancelar_seleccion()
      })
    },

    async cargar() {
      let dataSource = await fondeadoresApi.index(false)
      this.dataSourceGrid = dataSource.data
    }
  }

}
</script>


<style scoped lang="scss">
.btn-acciones:hover{
  cursor: pointer;
  filter: brightness(80%);
}
</style>